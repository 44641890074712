import Instagram from 'components/Icons/Share/Instagram/index'
import Telegram from 'components/Icons/Share/Telegram/index'
import URL from 'constants/urls'
import { XSocialMobile } from '../../../common/index'

export const socialLinks = [
  {
    icon: <Instagram width="26.66" isMobile />,
    to: URL.INSTAGRAM,
    type: 'Instagram',
  },
  {
    icon: <Telegram width="29.66" isMobile />,
    to: URL.TELEGRAM,
    type: 'Telegram',
  },
  {
    XIcon: XSocialMobile,
  },
]
