import { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import { setReferenceSite } from 'core/referenceSite/actionCreators'

export default function PersistReference({ children }) {
  const [search] = useSearchParams()
  const dispatch = useDispatch()

  const referenceSite = search?.get('reference')
  const referenceUrl = search?.get('referenceUrl')

  useEffect(() => {
    if (referenceSite) {
      dispatch(setReferenceSite(referenceSite))
      sessionStorage.setItem('reference', referenceSite)
    }
  }, [referenceSite])

  useEffect(() => {
    if (referenceUrl) {
      sessionStorage.setItem('referenceUrl', referenceUrl)
    }
  }, [referenceUrl])
  return children
}
