/* eslint-disable max-len */
import React from 'react'
import T from 'prop-types'
import cls from 'classnames'
import styles from './xsocial.module.scss'

function XSocial({ hovered, width = '26', size, color, isMobile }) {
  const renderColor = () => {
    if (hovered) return '#ffffff'
    return color || '#575757'
  }

  const renderStyle = () => {
    if (size === 'sm') return { transform: 'scale(0.6)' }
    return null
  }

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 52 42"
      width={width}
      className={cls({ [styles.hovered]: hovered })}
      style={renderStyle()}
    >
      {isMobile ? (
        <path
          fill="black"
          fillRule="evenodd"
          d="M31.3195 0H37.4108L24.1031 15.2492L39.7586 36H27.5005L17.8995 23.4148L6.91383 36H0.818853L15.0527 19.6892L0.0344238 0H12.6037L21.2821 11.5034L31.3195 0ZM29.1817 32.3446H32.5569L10.7697 3.46338H7.14768L29.1817 32.3446Z"
        />
      ) : (
        <path
          fill={renderColor()}
          fillRule="evenodd"
          d="M21.568 15.669 35.059 0h-3.197L20.148 13.605 10.791 0H0l14.148 20.573L0 37.005h3.197l12.371-14.368 9.881 14.368H36.24L21.567 15.669h.001zm-4.379 5.086-1.434-2.049L4.349 2.405H9.26l9.205 13.155 1.433 2.049 11.965 17.1h-4.91l-9.764-13.954z"
        />
      )}
    </svg>
  )
}

XSocial.propTypes = {
  isMobile: T.bool,
  hovered: T.bool,
  width: T.string,
  size: T.string,
  color: T.string,
}
export default XSocial
