import React, { useState, createElement } from 'react'
import { NavLink } from 'react-router-dom'
import routes from 'routing/path'
import eventTracker from 'utils/eventTracker'
import Animated from 'components/Animated'
import Star from 'components/Icons/Star/Index'
import CheckMark from 'components/Icons/CheckMark/Index'
import useCurrentWidth from 'hooks/useCurrentWidth'
import { useSelector } from 'react-redux/es/exports'
import styles from './joinclub.module.scss'

function JoinClub() {
  const [isHovered, setIsHovered] = useState(false)
  const width = useCurrentWidth()
  const profile = useSelector((s) => s.profile?.data)
  const isLogined = Boolean(profile)

  const renderIcon = (el, hoverState, isSmall) =>
    createElement(el, { hovered: hoverState, isSmall })
  const renderBtn = ({ to, el, type, name, category, action, label }) => (
    <div
      className={styles.item}
      onMouseEnter={() => setIsHovered(type)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <NavLink
        className={styles.link}
        to={to}
        onClick={() => eventTracker({ category, action, label })}
      >
        <span className={styles.forward_text}>
          {el && renderIcon(el, isHovered === type, width <= 768)}
          {name}
        </span>
      </NavLink>
    </div>
  )
  if (isLogined) return null
  return (
    <Animated>
      <div className={styles.wrapper}>
        <h1 className={styles.title}>join the club</h1>

        <div className={styles.actions}>
          {renderBtn({
            to: routes.withoutAuth.signup,
            el: Star,
            type: 'Signup',
            name: 'RESERVE',
            category: 'Sign Up',
            action: 'reserve_started',
            label: 'Reserve button click',
          })}
          {renderBtn({
            to: routes.withoutAuth.signin,
            el: CheckMark,
            type: 'Login',
            name: 'LOGIN',
            category: 'Login',
            action: 'login_started',
            label: 'Login button click',
          })}
        </div>
        <div className={styles.description}>
          {isHovered ? (
            <>
              <div className={styles.button_description}>
                {isHovered === 'Signup' && 'get your username'}
              </div>
              <div className={styles.button_description}>
                {isHovered === 'Login' && 'get your badge and nft'}
              </div>
            </>
          ) : (
            <div>game on!</div>
          )}
        </div>
      </div>
    </Animated>
  )
}

export default React.memo(JoinClub)
