import { combineReducers } from 'redux'

import profileReducer from './profile/reducer'
import publicProfileReducer from './publicProfile/reducer'
import refProfileReducer from './refProfile/reducer'
import achievementsReducer from './achievements/reducer'
import referenceSiteReducer from './referenceSite/reducer'

const rootReducer = combineReducers({
  profile: profileReducer,
  publicProfile: publicProfileReducer,
  refProfile: refProfileReducer,
  achievements: achievementsReducer,
  referenceSite: referenceSiteReducer,
})

export default rootReducer
