import { memo } from 'react'
import { useLocation, NavLink } from 'react-router-dom'
import cls from 'classnames'
import T, { PropTypes } from 'prop-types'

import styles from './nav_items.module.scss'

function NavItems({ links, hovered, setHovered }) {
  const { pathname } = useLocation()
  const isDisabled = pathname === '/signin'

  const renderLinks = ({ title, to, icon, router, type, XIcon }) => {
    if (router) {
      return (
        <NavLink
          key={title || type}
          to={to}
          className={cls(styles.link, {
            [styles.hover]: hovered === title,
            [styles.disabled]: isDisabled && title === 'login',
          })}
          onMouseEnter={() => setHovered(title)}
          onMouseLeave={() => setHovered('')}
        >
          {icon || title}
        </NavLink>
      )
    }

    if (icon) {
      return (
        <a
          key={title || type}
          href={to}
          target="_blank"
          rel="noreferrer"
          className={cls(styles.link, { [styles.hover]: hovered === type })}
          onMouseEnter={() => setHovered(type)}
          onMouseLeave={() => setHovered('')}
        >
          {title || icon}
        </a>
      )
    }

    return XIcon
  }

  return <div className={styles.header_section}>{links.length && links.map(renderLinks)}</div>
}

const links = PropTypes.shape({
  title: T.string,
  to: T.string,
  router: T.bool,
  map: T.func,
  type: T.string,
  icon: T.element,
})

NavItems.propTypes = {
  links: T.arrayOf(links),
  hovered: T.string,
  setHovered: T.func,
}

export default memo(NavItems)
