import { createContext, useCallback, useContext, useState, useMemo } from 'react'
import PropTypes from 'prop-types'

const UserSettingsContext = createContext()

export function UserSettingsProvider({ children }) {
  const [theme, setTheme] = useState('rainbowclassic')

  const switchTheme = useCallback((t) => {
    setTheme(t)
  }, [])

  const value = useMemo(
    () => ({
      theme,
      switchTheme,
    }),
    [theme, switchTheme],
  )

  return <UserSettingsContext.Provider value={value}>{children}</UserSettingsContext.Provider>
}

export const useUserSettings = () => useContext(UserSettingsContext)

UserSettingsProvider.propTypes = {
  children: PropTypes.node,
}
