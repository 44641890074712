import { takeLatest, all, put } from 'redux-saga/effects'
import types from '../types'

function* setRefProfileData({ payload }) {
  yield put({ type: types.SET_REF_PROFILE, payload })
}

export function* rootSagaRefProfile() {
  yield all([takeLatest(types.ADD_REF_PROFILE, setRefProfileData)])
}
