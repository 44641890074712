import types from '../types'

const initialState = {
  data: null,
}

const publicProfileReducer = (state = initialState, { type, payload } = {}) => {
  switch (type) {
    case types.SET_PUBLIC_PROFILE:
      return {
        ...state,
        data: payload,
      }

    default:
      return state
  }
}

export default publicProfileReducer
