import React from 'react'
import { createRoot } from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import ReactGA from 'react-ga4'
import { UserSettingsProvider } from 'context/userSettings'
import { setBaseEndpoint } from 'utils/apiClient'

import Application from './Application'

import 'react-toastify/dist/ReactToastify.css'
import './index.scss'
import { store, persistor } from './core/store'

const url =
  process.env?.REACT_APP_STAGE !== 'production' ? window?.env?.api?.url : window?.env?.prodApi?.url

setBaseEndpoint(url)

const rootElement = document.getElementById('root')
const root = createRoot(rootElement)

if (process.env?.REACT_APP_STAGE === 'production') {
  ReactGA.initialize('G-L0PSHDS3VB')
}

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter>
          <UserSettingsProvider>
            <Application />
          </UserSettingsProvider>
        </BrowserRouter>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
)
