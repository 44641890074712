import React from 'react'
import JoinClub from 'components/JoinClub'
import TopArrow from 'components/TopArrow'
import Animated from 'components/Animated'
import ArrowDown from 'components/ArrowDown'
import ComponentContainer from 'components/ComponentContainer'
import Actions from '../Actions'
import NewlyReserved from '../NewlyReserved'
import OurPromise from '../OurPromise'
// import FormInvite from '../FormInvite'

import styles from './main.module.scss'

function Main() {
  return (
    <div className={styles.section}>
      <ComponentContainer>
        <div className={styles.container}>
          <Animated>
            <div className={styles.subscription}>
              <JoinClub />
            </div>

            {/* <div className={styles.arrow_down_wrapper}>
              <ArrowDown />
            </div> */}

            <div className={styles.reserved_wrapper}>
              <NewlyReserved />
            </div>

            <div className={styles.actions_wrapper}>
              <Actions />
            </div>

            <div className={styles.promise_wrapper}>
              <OurPromise />
            </div>

            {/* <FormInvite /> */}

            <div className={styles.to_top}>
              <TopArrow />
            </div>
          </Animated>
        </div>
      </ComponentContainer>
    </div>
  )
}

export default React.memo(Main)
