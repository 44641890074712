/* eslint-disable max-len */
import T from 'prop-types'

function Star({ hovered, width = '37', height = '38', isSmall = false }) {
  const renderColor = () => '#ffffff'

  const renderStyle = () => {
    if (isSmall) return { transform: 'scale(0.6)' }
    return null
  }

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 74 75"
      style={renderStyle()}
    >
      <path
        fill={renderColor()}
        fillRule="evenodd"
        d="m40.435 2.763 8.538 17.818a9.022 9.022 0 0 0 4.117 4.201l17.561 8.648c3.21 1.595 3.21 6.225 0 7.82l-14.563 7.176a18.77 18.77 0 0 0-8.568 8.679l-7.085 14.781c-1.575 3.251-6.147 3.251-7.721 0l-7.085-14.781a18.776 18.776 0 0 0-8.569-8.679L2.497 41.25c-3.21-1.595-3.21-6.225 0-7.82l14.563-7.176c3.755-1.84 6.752-4.907 8.569-8.709l7.085-14.782c1.574-3.25 6.146-3.25 7.721 0z"
      />
    </svg>
  )
}

Star.propTypes = {
  hovered: T.bool,
  width: T.string,
  height: T.string,
  isSmall: T.bool,
}
export default Star
