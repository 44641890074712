import { useState } from 'react'
import { NavLink } from 'react-router-dom'
import { useSelector } from 'react-redux'
import cls from 'classnames'
import useScroll from 'hooks/useScroll'
import { useTheme } from 'context/theme'
import Animated from 'components/Animated/index'
import useCurrentWidth from 'hooks/useCurrentWidth'
import Facebook from 'components/Icons/Share/Facebook'
import Peer from 'components/Icons/Share/Peer'
import Twitter from 'components/Icons/Share/Twitter'
import Telegram from 'components/Icons/Share/Telegram'
import Linkedin from 'components/Icons/Share/Linkedin'
import Instagram from 'components/Icons/Share/Instagram/index'
// import Privacy from 'assets/PeerPrivacyPolicy.pdf'
// import Terms from 'assets/Peer-TermsandConditions.pdf'
import Logo from './components/Logo'
import FooterNavItems from './components/FooterNavItems'
import styles from './footer.module.scss'

const ShareData = [
  {
    id: 1,
    name: 'Peer',
    link: 'https://www.peer.inc',
  },
  {
    id: 2,
    name: 'Twitter',
    link: 'https://twitter.com/peernewworld',
  },
  {
    id: 3,
    name: 'Telegram',
    link: 'https://t.me/peer_inc',
  },
  {
    id: 4,
    name: 'Facebook',
    link: 'https://www.facebook.com/peerniverse',
  },
  {
    id: 5,
    name: 'Instagram',
    link: 'https://www.instagram.com/peernewworld/',
  },
  //   {
  //     id: 6,
  //     name: 'Linkedin',
  //     link: 'https://www.linkedin.com/company/peerinc/',
  //   },
]

const links = [
  { title: 'about', to: '/about', router: true },
  { title: 'help', to: '/help', router: true },
  { title: 'jobs', to: '/jobs', router: true },
  { title: 'assets', to: '/placeholder', router: true },
  { title: 'promise', to: '/promise', router: true },
]

function Footer() {
  const { scrollDirection } = useScroll()
  const [hovered, setHovered] = useState('')
  const { isLight } = useTheme()
  const width = useCurrentWidth()
  const referenceSite = sessionStorage.getItem('reference')

  const renderIcon = (name) => {
    if (name.toLowerCase() === 'facebook') {
      return <Facebook isLight={isLight} width={14} hovered={hovered === name} size="sm" />
    }
    if (name.toLowerCase() === 'twitter') {
      return <Twitter isLight={isLight} hovered={hovered === name} size="sm" />
    }
    if (name.toLowerCase() === 'peer') {
      return <Peer isLight={isLight} hovered={hovered === name} size="sm" />
    }
    if (name.toLowerCase() === 'telegram') {
      return <Telegram isLight={isLight} hovered={hovered === name} size="sm" />
    }
    if (name.toLowerCase() === 'linkedin') {
      return <Linkedin isLight={isLight} hovered={hovered === name} size="sm" />
    }
    if (name.toLowerCase() === 'instagram') {
      return <Instagram isLight={isLight} hovered={hovered === name} size="sm" />
    }
    return null
  }

  return (
    <footer
      className={cls(styles.container, {
        [styles.hidden]:
          scrollDirection === 'down' && scrollDirection !== 'bottom' && scrollDirection !== 'top',
        [styles.hidden]: referenceSite,
      })}
    >
      <Animated>
        <div className={styles.main_container}>
          <div className={styles.left_container}>
            <div className={styles.link_container}>
              <FooterNavItems links={links} />
            </div>
            <div className={styles.share_container}>
              {ShareData.map((item) => (
                <a
                  key={item.name}
                  href={item.link}
                  className={styles.share_icon}
                  target="_blank"
                  onMouseEnter={() => setHovered(item.name)}
                  onMouseLeave={() => setHovered('')}
                  rel="noreferrer"
                >
                  {renderIcon(item.name)}
                </a>
              ))}
            </div>
          </div>
          <div className={styles.right_container}>
            <NavLink className={styles.logo_container} to="/">
              <Logo isLight={isLight} width={width > 768 ? 110 : 73} />
            </NavLink>
            <div className={styles.copyright_container}>
              <span className={styles.copyright}>© PEER 2023. MADE WITH LOVE.</span>
              {/* <span className={styles.link}>
              <a href={Privacy} target="_blank" rel="noreferrer">
              Privacy
              </a>
              <a href={Terms} target="_blank" rel="noreferrer">
              Terms
              </a>
              <a href="https://peer.inc/labs" target="_blank" rel="noreferrer">
              About
              </a>
            </span> */}
            </div>
          </div>
        </div>
      </Animated>
    </footer>
  )
}

Footer.propTypes = {}

export default Footer
