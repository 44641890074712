import { useSelector } from 'react-redux'
import { Navigate } from 'react-router-dom'
import PropTypes from 'prop-types'
import path from 'routing/path'

function ProtectedRoute({ component: Component }) {
  const { data } = useSelector((state) => state.profile)
  const isLoggedIn = !!data

  if (!isLoggedIn) {
    return <Navigate to={path.withoutAuth.home} replace />
  }

  return <Component />
}

ProtectedRoute.propTypes = {
  component: PropTypes.elementType.isRequired,
}

export default ProtectedRoute
