import types from '../types'

export const setProfileData = (data) => ({
  type: types.ADD_PROFILE,
  payload: data,
})

export const setVerifyData = (data) => ({
  type: types.SET_VERIFY_DATA,
  payload: data,
})

export const fillNextMissedField = () => ({
  type: types.FILL_NEXT_MISSED_FIELD,
})
