import React, { useState } from 'react'
import cn from 'classnames'
import T from 'prop-types'
import URL from 'constants/urls'
import ArrowDown from 'components/Icons/ArrowDown'
import XSocialIcon from 'components/Icons/Share/XSocial'
import styles from './common.module.scss'

export function XSocialDesktop() {
  const [hoveredIcon, setHoveredIcon] = useState(false)

  return (
    <div
      className={styles.dropdown_wrapper}
      onMouseEnter={() => setHoveredIcon('xcosial')}
      onMouseLeave={() => setHoveredIcon(false)}
    >
      <div
        className={cn(styles.dropdown, {
          [styles.dropdown_hovered]: hoveredIcon === 'xcosial',
        })}
      >
        <div className={styles.dropbtn}>
          <XSocialIcon width="26" color="white" hovered={hoveredIcon === 'xcosial'} />
          <ArrowDown hovered={hoveredIcon === 'xcosial'} className={styles.arrow} />
        </div>
        <div className={styles.dropdown_content}>
          <a target="_blank" rel="noreferrer" href={URL.PEER_NEW_WORLD}>
            @peernewworld
          </a>
          <a target="_blank" rel="noreferrer" href={URL.WONKA_DISTRICT}>
            @wonkadistrict
          </a>
        </div>
      </div>
    </div>
  )
}

export function XSocialMobile({ handleClickSocial, toggleIcon }) {
  return (
    <div className={styles.container} onClick={handleClickSocial}>
      <div
        className={cn(styles.wrapper, {
          [styles.dropdown_clicked]: toggleIcon,
        })}
      >
        <div className={styles.dropdown_button}>
          <XSocialIcon width="32" isMobile />
        </div>
        <div
          className={cn(styles.content, {
            [styles.dropdown_clicked]: toggleIcon,
          })}
        >
          <a target="_blank" rel="noreferrer" href={URL.PEER_NEW_WORLD}>
            @peernewworld
          </a>
          <a target="_blank" rel="noreferrer" href={URL.WONKA_DISTRICT}>
            @wonkadistrict
          </a>
        </div>
      </div>
    </div>
  )
}

XSocialMobile.propTypes = {
  handleClickSocial: T.func,
  toggleIcon: T.bool,
}
