import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

export default function useIsProfilePublic() {
  const [isProfilePublic, setIsProfilePublic] = useState(false)

  const { pathname } = useLocation()

  useEffect(() => {
    if (pathname.includes('/verifiedbadge/')) {
      setIsProfilePublic(true)
    } else {
      setIsProfilePublic(false)
    }
  }, [pathname])

  return isProfilePublic
}
