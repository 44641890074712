import axios from 'axios'
import routes from 'routing/path'
import { setProfileData } from 'core/profile/actionCreators'
import { store } from '../core/store'

let baseEndpoint = ''
let temporaryEndpoint = ''

const getBaseHeaders = () => ({
  Accept: 'application/json, text/plain, */*',
  'Content-Type': 'application/json',
  'X-CSRF-Token': undefined,
})

export const setBaseEndpoint = (ep) => {
  baseEndpoint = ep
}

export const setEndpoint = (ep) => {
  temporaryEndpoint = ep
}

const instance = axios.create()

const callApi = async (url, { headers = {}, params = {}, data, ...restOptions }) => {
  const config = {
    url: temporaryEndpoint ? `${temporaryEndpoint}${url}` : `${baseEndpoint}${url}`,
    headers: { ...getBaseHeaders(), ...headers },
    params: { ...params },
    data,
    withCredentials: true,
    ...restOptions,
  }

  temporaryEndpoint = ''

  if (restOptions.method === 'POST' && !config.data) {
    config.data = {}
  }

  const request = await instance.request(config, { parse: true })

  return request
}

instance.interceptors.response.use(
  (res) => res,
  (error) => {
    if (error.response.status >= 500) {
      window.location.href = `${window.location.origin}${routes.withoutAuth.error}`
    }

    if (error.response.status === 401) {
      store.dispatch(setProfileData(null))
      // window.location.href = `${window.location.origin}${routes.withoutAuth.signin}` //removed for login flow
    }
    return Promise.reject(error)
  },
)

export default {
  get: (url, options) => callApi(url, { ...options, method: 'GET' }),
  post: (url, options) => callApi(url, { ...options, method: 'POST' }),
  put: (url, options) => callApi(url, { ...options, method: 'PUT' }),
  delete: (url, options) => callApi(url, { ...options, method: 'DELETE' }),
}
