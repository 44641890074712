import types from '../types'

const initialState = {
  data: null,
  username: null,
  loading: false,
}

const achievementsReducer = (state = initialState, { type, payload } = {}) => {
  switch (type) {
    case types.SET_ACHIEVEMENTS_USERNAME:
      return {
        ...state,
        username: payload.username,
      }

    case types.GET_ACHIEVEMENTS_START:
      return {
        ...state,
        loading: true,
      }

    case types.GET_ACHIEVEMENTS_FAILURE:
      return {
        ...initialState,
        loading: false,
      }

    case types.GET_ACHIEVEMENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: {
          ...payload,
        },
      }

    default:
      return state
  }
}

export default achievementsReducer
