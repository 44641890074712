import { useState, useEffect } from 'react'

const getWidth = () =>
  document.documentElement.getBoundingClientRect().width || document.body.clientWidth

export default function useCurrentWidth() {
  const [width, setWidth] = useState(getWidth())
  useEffect(() => {
    let timeoutId = null
    const resizeListener = () => {
      clearTimeout(timeoutId)
      timeoutId = setTimeout(() => setWidth(getWidth()), 150)
    }
    window.addEventListener('resize', resizeListener)

    return () => {
      window.removeEventListener('resize', resizeListener)
    }
  }, [])

  return width
}
