import { fork } from 'redux-saga/effects'

import { rootSagaProfile } from './profile/actions'
import { rootSagaPublicProfile } from './publicProfile/actions'
import { rootSagaRefProfile } from './refProfile/actions'
import { rootSagaReferenceSite } from './referenceSite/actions'
// eslint-disable-next-line import/no-cycle
import { rootSagaAchievements } from './achievements/actions'

export function* rootSaga() {
  yield fork(rootSagaProfile)
  yield fork(rootSagaPublicProfile)
  yield fork(rootSagaRefProfile)
  yield fork(rootSagaAchievements)
  yield fork(rootSagaReferenceSite)
}
