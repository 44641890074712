export default {
  addNewUser: '/barong/identity/users/new',
  updateUser: '/barong/resource/users/update',
  verifyOTP: '/barong/identity/sessions/verify/otp',
  resetPasswordLink: '/barong/identity/users/password/generate_code',
  resetPassword: '/barong//identity/users/password/confirm_code',
  sendOTP: '/barong/identity/sessions/verify_user',
  sendOTPNew: '/barong/identity/sessions/new',
  checkUsername: '/barong/identity/users/username',
  getReferral: '/barong/resource/referrals',
  getPublicUserReferrals: '/barong/public/users/referrals',
  getPublicReferral: '/base/identity/users/username/referral',
  getPublicUserAchievements: '/barong/public/users/?username=',
  fetchUser: '/barong/resource/users/me',
  uploadImage: '/barong/resource/users/media',
  deleteImage: '/barong/resource/users/remove_media',
  peatioMe: '/base/account/members/me',
  getUserPublicInfo: '/barong/public/users',
  getRandomUser: '/barong/public/users/random',
  getUserAchievements: '/barong/public/users/?username=',
  loginUser: '/barong/identity/sessions',
  loginUserOTP: '/barong/identity/sessions/new',
  setPassword: '/barong/resource/users/set-password',
  verifyPhone: '/barong/resource/user/phone_number',
  verifyEmail: '/barong/resource/user/email',
  sendPhoneOTP: '/barong/resource/user/phone_number/verify',
  sendEmailOTP: '/barong/resource/user/email/verify',
}
