import { useState, memo, useMemo, useEffect } from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import cls from 'classnames'
import useScroll from 'hooks/useScroll'
import routes from 'routing/path'
import useCurrentWidth from 'hooks/useCurrentWidth'
import eventTracker from 'utils/eventTracker'
import redirectIfReference from 'utils/redirectIfReference'
import logo from 'assets/logo.svg'
import peermarketLogo from 'assets/peermarketlogo.png'
import Instagram from 'components/Icons/Share/Instagram/index'
import Telegram from 'components/Icons/Share/Telegram/index'
import useFetchUser from 'hooks/requests/useFetchUser'
import apiClient from 'utils/apiClient'
import isEqual from 'lodash.isequal'
import URL from 'constants/urls'
import { XSocialDesktop } from './components/common/index'
import NavItems from './components/NavItems'
import MobileMenu from './components/MobileMenu/index'
import styles from './header.module.scss'
import UserPhoto from './components/UserPhoto'

function Header() {
  const width = useCurrentWidth()
  const isMobile = useMemo(() => width <= 1200, [width])
  const { scrollDirection } = useScroll()
  const [isOpen, setIsOpen] = useState(false)
  const { pathname } = useLocation()
  const isDisabled = pathname === '/signin'
  const [hovered, setHovered] = useState('')
  const referenceSite = sessionStorage.getItem('reference')
  const referenceUrl = sessionStorage.getItem('referenceUrl')
  const profile = useSelector((s) => s.profile?.data, isEqual)
  const isLogined = profile?.state === 'active'
  const { setUserData } = useFetchUser()
  useEffect(() => {
    setUserData(true)
  }, [])

  const leftSection = [
    {
      icon: <Instagram width="20" isLight isHovered={hovered === 'Instagram'} />,
      to: URL.INSTAGRAM,
      type: 'Instagram',
    },
    {
      icon: <Telegram width="23.5" isLight isHovered={hovered === 'Telegram'} />,
      to: URL.TELEGRAM,
      type: 'Telegram',
    },
    {
      XIcon: <XSocialDesktop />,
    },
  ]

  const rightSection = [
    { title: 'about', to: '/about', router: true },
    { title: 'help', to: '/help', router: true },
    isLogined
      ? {
          icon: <UserPhoto profile={profile} />,
          to: routes.withoutAuth.user.index,
          type: 'Profile',
          title: 'Profile',
          router: true,
        }
      : { title: 'login', to: '/signin', router: true },
  ]

  const handleLoginClick = () => {
    const options = {
      category: 'Login',
      action: 'login_started',
      label: 'Login button click',
    }

    eventTracker(options)
  }
  const referenceLink = async () => {
    try {
      await apiClient.delete('/barong/identity/sessions')
    } finally {
      redirectIfReference()
    }

    return null
  }

  return (
    <header
      className={cls(
        styles.container,
        {
          [styles.hidden]:
            scrollDirection === 'down' &&
            scrollDirection !== 'bottom' &&
            scrollDirection !== 'top' &&
            !isOpen,
        },
        {
          [styles.bg]: scrollDirection === 'up' || scrollDirection === 'bottom',
        },
      )}
    >
      <div className={cls(styles.main_container, { [styles.center]: referenceSite })}>
        {!referenceSite && (
          <NavItems hovered={hovered} setHovered={setHovered} links={leftSection} />
        )}
        {referenceSite === 'peermarket' ? (
          <div className={styles.logo_wrapper} onClick={referenceLink}>
            <img src={peermarketLogo} className={styles.logo_market} alt="Peer" />
          </div>
        ) : (
          <NavLink className={styles.logo_wrapper} to="/">
            <img src={logo} className={styles.logo} alt="Peer" height={isMobile ? 26.66 : 40} />
          </NavLink>
        )}
        {!referenceSite && (
          <>
            <NavItems hovered={hovered} setHovered={setHovered} links={rightSection} />
            {isMobile &&
              (isLogined ? (
                <UserPhoto profile={profile} />
              ) : (
                <div className={styles.rightGroupMobile}>
                  <NavLink
                    className={cls(styles.link, {
                      [styles.right_mobile_nav]: true,
                      [styles.disabled]: isDisabled,
                    })}
                    to={`${routes.withoutAuth.signin}?page=captcha`}
                    onClick={handleLoginClick}
                  >
                    <span>login</span>
                  </NavLink>
                  <MobileMenu isOpen={isOpen} setIsOpen={setIsOpen} />
                </div>
              ))}
          </>
        )}
      </div>
    </header>
  )
}

Header.propTypes = {}

export default memo(Header)
