/* eslint-disable no-buffer-constructor */
/* eslint-disable max-len */

import React, { useCallback, useState, useMemo } from 'react'

import styles from './arrow.module.scss'

function TopArrow() {
  const [isHovered, setIsHovered] = useState(false)

  const image = useMemo(() => {
    const svgStr = `<svg xmlns="http://www.w3.org/2000/svg" width="62" height="69"><path fill-rule="evenodd" opacity="${
      isHovered ? '1' : '0.1'
    }" fill="${
      isHovered ? '#000' : '#FFF'
    }" d="M4.53 32.218c2.535 2.465 6.34 3.288 9.3.821l8.876-6.576v35.76c0 3.699 2.959 6.166 6.342 6.166h4.227c3.805 0 6.341-2.878 6.341-6.166v-35.76l8.878 6.576c2.959 2.056 7.186 1.644 9.3-1.233l2.537-3.288c2.114-2.878 1.69-6.577-1.269-9.042L36.235 2.211c-2.96-2.466-7.187-2.466-10.57 0L3.26 19.886c-2.959 2.055-3.381 6.166-1.267 9.043l2.537 3.289z" /></svg>`

    const svg = new Blob([svgStr], { type: 'image/svg+xml' })

    return URL.createObjectURL(svg)
  }, [isHovered])

  const onClick = useCallback(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    })
  }, [])

  return (
    <div className={styles.btn}>
      <div
        className={styles.icon}
        onClick={onClick}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <img src={image} alt="Top arrow" />
      </div>
      top
    </div>
  )
}

export default React.memo(TopArrow)
