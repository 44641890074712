import { createContext, useCallback, useContext, useState, useMemo } from 'react'
import PropTypes from 'prop-types'

const ThemeContext = createContext()

export function ThemeProvider({ children }) {
  const [isLight, setIsLight] = useState(false)

  const switchTheme = useCallback(() => {
    setIsLight(!isLight)
  }, [isLight])

  const value = useMemo(() => ({ isLight, switchTheme }), [isLight])

  return <ThemeContext.Provider value={value}>{children}</ThemeContext.Provider>
}

export const useTheme = () => useContext(ThemeContext)

ThemeProvider.propTypes = {
  children: PropTypes.node,
}
