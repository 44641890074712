/* eslint-disable max-len */
import React from 'react'
import T from 'prop-types'
import cls from 'classnames'
import styles from './styles.module.scss'

function ArrowDown({ hovered, width = '12', size }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={width}
      className={cls({ [styles.hovered]: hovered })}
    >
      <path
        fillRule="evenodd"
        fill={hovered ? '#ffffff' : '#5b5b5b'}
        d="M12 17.414 3.293 8.707l1.414-1.414L12 14.586l7.293-7.293 1.414 1.414L12 17.414z"
      />
    </svg>
  )
}

ArrowDown.propTypes = {
  hovered: T.bool,
  width: T.string,
  size: T.string,
}
export default ArrowDown
