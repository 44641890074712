import persistReducer from 'redux-persist/es/persistReducer'
import storage from 'redux-persist/lib/storage'
import { getMissedFields } from 'utils/helpers'

import encryptor from '../encryptor'
import types from '../types'

const initialState = {
  data: null,
  verifyData: null,
  missedFields: [],
}

const profileReducer = (state = initialState, { type, payload } = {}) => {
  switch (type) {
    case types.SET_PROFILE:
      return {
        ...state,
        data: payload,
        missedFields: getMissedFields(payload),
      }

    case types.SET_VERIFY_DATA:
      return {
        ...state,
        verifyData: payload,
      }

    case types.FILL_NEXT_MISSED_FIELD:
      return {
        ...state,
        missedFields: state.missedFields.slice(1),
      }

    default:
      return state
  }
}

const profileConfig = {
  key: 'peer_profile',
  storage,
  transforms: [encryptor],
}

export default persistReducer(profileConfig, profileReducer)
