const desktop = {
  withoutAuth: {
    home: '/',
    about: '/about',
    help: '/help',
    answer: '/help/:answerId',
    wallet: '/wallet',
    notFound: '/notFound',
    verified_badge: '/verified-badge',
    terms_privacy: '/terms-privacy',
    promise: '/promise',
    signup: '/signup',
    signin: '/signin',
    placeholder: '/placeholder',
    weheartyou: '/weheartyou',
    emoji: '/nftnfe',
    error: '/error',
    reset_password: '/reset_password/:token',
    jobs: 'jobs',

    user: {
      index: '/verifiedbadge',
      publicProfile: '/verifiedbadge/:username',
    },
  },
  withAuth: {},
}

export default desktop
