import React from 'react'
import PropTypes from 'prop-types'
import styles from './styles.module.scss'

function UserPhoto({ profile }) {
  const url = profile?.profile_url?.url

  return (
    <div className={styles.container}>
      <div className={styles.user_photo_wrapper}>
        {url ? (
          <img className={styles.user_photo} src={url} alt="user" />
        ) : (
          <div className={styles.empty_slot}>
            {profile?.username?.[0] || profile?.full_name?.[0] || profile?.email?.[0]}
          </div>
        )}
      </div>
    </div>
  )
}

UserPhoto.propTypes = {
  profile: PropTypes.object,
}

export default UserPhoto
