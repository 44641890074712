import ReactGA from 'react-ga4'

const eventTracker = ({ category, action, label, value, nonInteraction = false }) => {
  let options = { category, action }

  if (label) options = { ...options, label }
  if (value) options = { ...options, value }
  if (nonInteraction) options = { ...options, nonInteraction }

  if (process.env?.REACT_APP_STAGE === 'production' && category && action) {
    ReactGA.event(options)
  }

  return null
}

export default eventTracker
