import { useEffect, useState } from 'react'
import path from 'components/API/Paths'
import api from 'utils/apiClient'

export default function useRandomUser() {
  const [data, setData] = useState()
  const [status, updateStatus] = useState('idle') // idle, loading, error
  const [errorMsg, setErrorMsg] = useState('')

  const getData = async () => {
    updateStatus('loading')
    setErrorMsg('')

    try {
      const response = await api.get(`${path.getRandomUser}`)

      setData(response)
      updateStatus('success')

      updateStatus('idle')
    } catch (error) {
      updateStatus('error')
      setErrorMsg(error)
    }

    updateStatus('idle')
  }

  useEffect(() => {
    getData()
  }, [])

  return { data: data?.data, status, errorMsg }
}
