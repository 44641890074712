/* eslint-disable max-len */

import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { NavLink } from 'react-router-dom'
import Animated from 'components/Animated'

import Hi from 'assets/emoji/hi.png'
import Monkey from 'assets/emoji/monkey.png'
import CheckMark from 'assets/emoji/peer_checkmark.png'

import styles from './actions.module.scss'

function Action({ icon, className, text, title, link, animated = false }) {
  return (
    <div className={styles.action}>
      <NavLink
        key={title}
        to={link}
        className={classNames(styles.link, { [styles.animated]: animated })}
      >
        <div className={classNames(styles.icon, className)}>
          <img src={icon} alt={title} />

          <div className={styles.title}>{title}</div>
        </div>
      </NavLink>

      <div className={styles.text}>{text}</div>
    </div>
  )
}

function Actions() {
  const data = [
    {
      icon: Hi,
      className: styles.hi,
      text: 'learn more about peer',
      title: 'yo!',
      link: '/about',
      animated: true,
    },
    {
      icon: CheckMark,
      className: styles.checkmark,
      text: 'what’s new',
      title: 'latest',
      link: '/placeholder',
      animated: true,
    },
    {
      icon: Monkey,
      className: styles.iceCream,
      text: 'the scoop on peermojis',
      title: 'drops',
      link: '/nftnfe',
      animated: true,
    },
  ]

  return (
    <div className={styles.list}>
      {data.map((d) => (
        <Animated key={d.title}>
          <Action {...d} />
        </Animated>
      ))}
    </div>
  )
}

export default React.memo(Actions)

Action.propTypes = {
  icon: PropTypes.node,
  className: PropTypes.string,
  text: PropTypes.string,
  title: PropTypes.string,
  link: PropTypes.string,
  animated: PropTypes.bool,
}
