import { useState, memo } from 'react'
import { useLocation, NavLink } from 'react-router-dom'
import cls from 'classnames'

import T, { PropTypes } from 'prop-types'

import styles from './footer_nav_items.module.scss'

function FooterNavItems({ links }) {
  const [hovered, setHovered] = useState('')
  const { pathname } = useLocation()

  return (
    <div className={styles.section}>
      {links.map((link) =>
        link.router ? (
          <NavLink
            key={link.title}
            to={link.to}
            className={cls(
              styles.link,
              (hovered === link.title || pathname === link.to) && styles.hover,
            )}
            onMouseEnter={() => setHovered(link.title)}
            onMouseLeave={() => setHovered('')}
          >
            <span className={styles.scallable}>{link.title}</span>

            {pathname === link.to && <div className={styles.dot} />}
          </NavLink>
        ) : (
          <a
            key={link.title}
            href={link.to}
            target="_blank"
            rel="noreferrer"
            className={cls(styles.link, hovered === link.title && styles.hover)}
            onMouseEnter={() => setHovered(link.title)}
            onMouseLeave={() => setHovered('')}
          >
            <span className={styles.scallable}>{link.title}</span>
          </a>
        ),
      )}
    </div>
  )
}

const Link = PropTypes.shape({ title: T.string, to: T.string, router: T.bool })

FooterNavItems.propTypes = {
  links: T.arrayOf(Link),
  map: T.func,
}

export default memo(FooterNavItems)
