import { useMemo } from 'react'
import { useSelector } from 'react-redux/es/exports'
import useIsProfilePublic from 'hooks/useIsProfilePublic'

export const getAchievementsByUsername = () => {
  const {
    username: currentUsername,
    data: currentUserData,
    loading: currentLoading,
  } = useSelector((state) => state.achievements)

  const {
    username: publicUsername,
    data: publicUserData,
    loading: publicLoading,
  } = useSelector((state) => state.publicProfile)

  const profile = useSelector((s) => s.profile?.data)

  const isProfilePublic = useIsProfilePublic()
  const isLogined = !!profile

  const selectedDataForCurrent = useMemo(
    () =>
      currentUsername
        ? currentUserData?.referrals?.find((r) => r?.username === currentUsername) ||
          currentUserData
        : currentUserData,
    [currentUsername, currentLoading],
  )
  const selectedDataForPublic = useMemo(
    () =>
      publicUsername
        ? publicUserData?.referrals?.find((r) => r?.username === publicUsername) || publicUserData
        : publicUserData,
    [publicUsername, publicLoading],
  )

  if (isLogined && !isProfilePublic) {
    return {
      ...selectedDataForCurrent,
    }
  }

  return {
    ...selectedDataForPublic,
  }
}

export const toLocalDate = (date) => {
  const m = new Date(date)
  return `${`0${m.getUTCMonth() + 1}`.slice(-2)}/${`0${m.getUTCDate()}`.slice(
    -2,
  )}/${`${new Date().getUTCFullYear()}`.slice(-2)} ${`0${m.getUTCHours()}`.slice(
    -2,
  )}:${`0${m.getUTCMinutes()}`.slice(-2)}`
}

export const splitAddress = (address) => `${address.slice(0, 4)}...${address.slice(-5)}`

export const passedTime = (data) => {
  const { date, s = ' s', m = ' m', h = ' h', d = ' d' } = data

  if (!date) return `0${s}`

  const parsedDate = Date.parse(new Date(Number(date)))
  let timeLeft
  const time = Date.now()
  const newDate = (time - parsedDate) / 1000
  if (newDate < 60) timeLeft = `${newDate.toFixed(0)}${s} ago`
  if (newDate >= 60 && newDate <= 3600) timeLeft = `${(newDate / 60).toFixed(0)}${m} ago`
  if (newDate >= 3600 && newDate <= 86400) timeLeft = `${(newDate / 3600).toFixed(0)}${h} ago`
  if (newDate >= 86400) timeLeft = `${(newDate / 86400).toFixed(0)}${d} ago`
  return timeLeft
}

export const getMissedFields = (profileData) => {
  const result = []

  if (!profileData) return result

  if (!profileData.first_name || !profileData.last_name) {
    result.push('name')
  }

  if (!profileData.dob && !profileData?.profiles?.[0]?.dob) {
    result.push('dob')
  }

  if (!profileData.email) {
    result.push('email')
  }

  if (!profileData.username) {
    result.push('username')
  }

  if (!profileData.phone_number && !profileData?.phones[0]?.number) {
    result.push('phone')
  }

  return result
}
