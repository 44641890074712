import { takeLatest, all, put } from 'redux-saga/effects'
import types from '../types'

function* setProfileData({ payload }) {
  yield put({ type: types.SET_PROFILE, payload })
}

export function* rootSagaProfile() {
  yield all([takeLatest(types.ADD_PROFILE, setProfileData)])
}
