import types from '../types'

const initialState = {
  data: null,
}

const referenceSiteReducer = (state = initialState, { type, payload } = {}) => {
  switch (type) {
    case types.SET_REFERENCE_SITE:
      return {
        ...state,
        data: payload,
      }

    default:
      return state
  }
}

export default referenceSiteReducer
