import marketUrl from 'constants/url'

export default function redirectIfReference() {
  const referenceUrl = sessionStorage.getItem('referenceUrl')

  const redirect = (url) => {
    window.location.href = url
  }

  if (referenceUrl) {
    redirect(`${marketUrl}/entry?route=${encodeURIComponent(referenceUrl)}`)
  } else {
    redirect(`${marketUrl}/entry`)
  }

  sessionStorage.removeItem('reference')
  sessionStorage.removeItem('referenceUrl')
}
