import { createTransform } from 'redux-persist'
import CryptoJS from 'crypto-js'

const ENCRYPTION_KEY = 'peer-encrypt-secret-key'

const encryptor = createTransform(
  (inboundState) => {
    const ciphertext = CryptoJS.AES.encrypt(JSON.stringify(inboundState), ENCRYPTION_KEY).toString()
    return ciphertext
  },

  (outboundState) => {
    const bytes = CryptoJS.AES.decrypt(outboundState, ENCRYPTION_KEY)
    const plaintext = bytes.toString(CryptoJS.enc.Utf8)
    return JSON.parse(plaintext)
  },
)

export default encryptor
