/* eslint-disable max-len */
import T from 'prop-types'

function CheckMark({ hovered, width = '36', height = '26', isSmall }) {
  const renderColor = () => '#ffffff'

  const renderStyle = () => {
    if (isSmall) return { transform: 'scale(0.6)' }
    return null
  }

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 72 52"
      style={renderStyle()}
    >
      <path
        fill={renderColor()}
        fillRule="evenodd"
        d="m19.555 45.553.002.003 2.838 2.79.006.005c3.769 3.707 9.88 3.707 13.649.001l34.348-33.774a5.314 5.314 0 0 0 0-7.605l-5.346-5.257c-2.136-2.1-5.598-2.1-7.734 0L29.226 29.339 14.995 15.346c-2.136-2.1-5.599-2.1-7.735 0l-5.8 5.704a4.688 4.688 0 0 0 0 6.71l18.095 17.793z"
      />
    </svg>
  )
}

CheckMark.propTypes = {
  hovered: T.bool,
  width: T.string,
  height: T.string,
  isSmall: T.bool,
}
export default CheckMark
