import BottomSpacer from 'components/BottomSpacer/index'
import Main from './components/Main'
import Hero from './components/Hero'

function Home() {
  return (
    <>
      <Hero />
      <Main />
      <BottomSpacer />
    </>
  )
}

export default Home
