import { takeLatest, all, put } from 'redux-saga/effects'
import types from '../types'

function* setPublicProfileData({ payload }) {
  yield put({ type: types.SET_PUBLIC_PROFILE, payload })
}

export function* rootSagaPublicProfile() {
  yield all([takeLatest(types.ADD_PUBLIC_PROFILE, setPublicProfileData)])
}
