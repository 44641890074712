/* eslint-disable no-undef */
/* eslint-disable react/prop-types */
import { useEffect } from 'react'
import withRouter from './withRouter'

function ScrollToTop({ children, location: { pathname } }) {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  return children || null
}

export default withRouter(ScrollToTop)
