import cls from 'classnames'
import T from 'prop-types'
import styles from './logo.module.scss'

function Logo({ hovered, width = 110, isLight }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      viewBox="0 0 281 80"
      className={cls({ [styles.hovered]: hovered })}
    >
      <path
        fill={isLight ? '#4d4d4d' : '#4d4d4d'}
        fillRule="evenodd"
        d={`m280.024 54.039-5.828 2.851a7.48 7.48 0 0 0-3.428 3.447l-2.836 5.871c-.629 1.29-2.459 
        1.29-3.089 0l-2.835-5.871a7.475 7.475 0 0 0-3.428-3.447l-5.828-2.851c-1.284-.632-1.284-2.472 
        0-3.105l5.828-2.85a7.396 7.396 0 0 0 3.428-3.459l2.835-5.871c.63-1.291 2.46-1.291 3.089 
        0l3.417 7.076a3.591 3.591 0 0 0 1.649 1.669l7.026 3.435c1.284.633 1.284 2.473 0 3.105zM260.921 
        18.28h-3.842c-11.569 0-20.959 9.439-20.959 21.071v19.926a2.654 2.654 0 0 1-2.641 2.655h-12.902a2.655 
        2.655 0 0 1-2.641-2.655V39.351c0-21.742 17.53-39.353 39.143-39.353h3.842a2.654 2.654 0 0 1 
        2.64 2.655v12.971c0 1.462-1.187 2.656-2.64 2.656zm-50.363 16.187a2.643 2.643 0 0 1-2.63 
        2.424h-43.613c.363 1.619.934 3.118 1.732 4.494 1.466 2.533 3.538 4.47 6.215 5.798 2.678 
        1.328 5.827 1.985 9.451 1.985 3.247 0 6.092-.487 8.54-1.474a19.955 19.955 0 0 0 
        4.894-2.826c1.091-.852 2.642-.743 3.574.28l5.96 6.492a2.677 2.677 0 0 1-.144 3.752c-2.448 
        2.265-5.33 4.08-8.675 5.432-4.215 1.705-9.086 2.558-14.586 2.558-6.941 
        0-13.023-1.364-18.27-4.093-5.245-2.728-9.304-6.467-12.163-11.205-2.871-4.739-4.301-10.061-4.301-16.2 
        0-6.139 1.419-11.583 4.242-16.321 2.821-4.737 6.686-8.452 11.593-11.144 4.893-2.68 10.674-4.032 
        16.621-4.032 5.95 0 11.328 1.28 16.114 3.813h.024c4.784 2.533 8.577 6.175 11.364 10.913 2.785 4.738 
        4.179 10.414 4.179 17.003 0 .682-.048 1.462-.121 
        2.326v.025zm-18.209-13.154c-1.284-2.351-3.052-4.191-5.319-5.518-2.264-1.315-4.905-1.986-7.922-1.986-3.017 
        0-5.67.658-7.973 1.986h-.011c-2.29 1.327-4.084 3.179-5.368 5.566-.823 1.547-1.38 3.277-1.672 
        5.177h29.961c-.278-1.925-.847-3.667-1.696-5.225zm-53.16 13.154a2.643 2.643 0 0 1-2.63 2.424H92.946c.363 
        1.619.932 3.118 1.732 4.494 1.466 2.533 3.537 4.47 6.215 5.798 2.678 1.328 5.828 1.985 9.449 1.985 3.247 
        0 6.094-.487 8.542-1.474a19.992 19.992 0 0 0 4.894-2.826c1.09-.852 2.642-.743 3.574.28l5.96 6.492a2.677 
        2.677 0 0 1-.144 3.752c-2.448 2.265-5.33 4.08-8.675 5.432-4.215 1.705-9.086 2.558-14.587 2.558-6.942 
        0-13.022-1.364-18.268-4.093-5.246-2.728-9.305-6.467-12.164-11.205-2.871-4.739-4.301-10.061-4.301-16.2 
        0-6.139 1.417-11.583 4.24-16.321 2.823-4.737 6.687-8.452 11.595-11.144C95.901 1.739 101.68.387 
        107.629.387c5.948 0 11.328 1.28 16.112 3.813h.024c4.786 2.533 8.579 6.175 11.366 10.913 2.785 
        4.738 4.179 10.414 4.179 17.003 0 .682-.049 1.462-.121 2.326v.025zM120.98 
        21.313c-1.284-2.351-3.054-4.191-5.319-5.518-2.266-1.315-4.905-1.986-7.923-1.986-3.017 
        0-5.669.658-7.972 1.986h-.012c-2.289 1.327-4.083 3.179-5.367 5.566-.823 
        1.547-1.38 3.277-1.672 5.177h29.961c-.278-1.925-.849-3.667-1.696-5.225zM52.578 54.576c-5.124 
        2.314-11.23 3.471-18.317 3.471H20.958a2.654 2.654 0 0 0-2.64 2.655V77.34a2.655 2.655 0 0 1-2.641 
        2.655H2.64A2.654 2.654 0 0 1 0 77.34V3.054A2.646 2.646 0 0 1 2.64.4h31.633c7.087 0 13.193 1.157 
        18.318 3.459h-.013c5.126 2.314 9.087 5.627 11.872 9.95 2.788 4.325 4.181 9.562 4.181 15.469 0 
        5.908-1.393 11.022-4.181 15.347-2.785 4.323-6.746 7.636-11.872 
        9.951zm-6.662-35.529c-2.799-2.424-7.015-3.642-12.672-3.642H18.318v27.624h14.926c5.657 0 9.885-1.217 
        12.672-3.641v.011c2.785-2.423 4.179-5.725 4.179-10.121 0-4.397-1.394-7.808-4.179-10.231z`}
      />
    </svg>
  )
}

Logo.propTypes = {
  hovered: T.bool,
  width: T.number,
  isLight: T.bool,
}

export default Logo
