import React from 'react'
import Animated from 'components/Animated'

import styles from './hero.module.scss'

function Hero() {
  return (
    <div className={styles.container}>
      <Animated>
        <div className={styles.section_background} />
      </Animated>
    </div>
  )
}

export default React.memo(Hero)
