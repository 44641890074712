import React from 'react'
import { NavLink } from 'react-router-dom'
import HeartOutline from 'assets/heartoutline.svg'
import routing from 'routing/path'

import styles from './our_promise.module.scss'

function OurPromise() {
  return (
    <div className={styles.container}>
      <img src={HeartOutline} alt="Heart" />
      <NavLink className={styles.link} to={routing.withoutAuth.promise}>
        our promise
      </NavLink>
    </div>
  )
}

export default React.memo(OurPromise)
