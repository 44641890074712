/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable max-len */

import React from 'react'
import Check from 'assets/checkRound.svg'
import useRandomUser from 'hooks/requests/useRandomUser'

import styles from './styles.module.scss'

function NewlyReserved() {
  const { data } = useRandomUser()
  const url = process.env?.REACT_APP_STAGE !== 'production' ? `s.p.zone` : `p.zone`

  return (
    <>
      {data && (
        <div className={styles.container}>
          <div className={styles.title}>NEWLY RESERVED</div>
          <div className={styles.content}>{data?.username}</div>
          <div className={styles.user_card}>
            {data?.profile_url ? (
              <div>
                <img
                  className={styles.user_photo}
                  src={data?.profile_url?.small?.url}
                  width={68}
                  height={68}
                  alt="user"
                />
              </div>
            ) : (
              <div className={styles.user_image}>{data?.initial || data?.username[0]}</div>
            )}
            <div className={styles.user_data}>
              <div className={styles.user_name}>
                {data?.full_name}{' '}
                {data?.verified && <img src={Check} alt="Check" width={12.5} height={14} />}
              </div>
              <a
                className={styles.button}
                href={`https://${url}/${data?.username}`}
                target="_blank"
                rel="noreferrer"
              >
                {`${url}/${data?.username}`}
              </a>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default React.memo(NewlyReserved)

NewlyReserved.propTypes = {}
