const types = {
  ADD_PROFILE: 'ADD_PROFILE',
  SET_PROFILE: 'SET_PROFILE',
  SET_VERIFY_DATA: 'SET_VERIFY_DATA',
  ADD_PUBLIC_PROFILE: 'ADD_PUBLIC_PROFILE',
  SET_PUBLIC_PROFILE: 'SET_PUBLIC_PROFILE',
  ADD_REF_PROFILE: 'ADD_REF_PROFILE',
  SET_REF_PROFILE: 'SET_REF_PROFILE',
  GET_ACHIEVEMENTS_START: 'GET_ACHIEVEMENTS_START',
  GET_ACHIEVEMENTS_FAILURE: 'GET_ACHIEVEMENTS_FAILURE',
  GET_ACHIEVEMENTS_SUCCESS: 'GET_ACHIEVEMENTS_SUCCESS',
  SET_ACHIEVEMENTS_DATA_BY_KEY: 'SET_ACHIEVEMENTS_DATA_BY_KEY',
  SET_ACHIEVEMENTS_USERNAME: 'SET_ACHIEVEMENTS_USERNAME',
  ADD_REFERENCE_SITE: 'ADD_REFERENCE_SITE',
  SET_REFERENCE_SITE: 'SET_REFERENCE_SITE',
  DELETE_PROFILE_PICTURE: 'DELETE_PROFILE_PICTURE',
  FILL_NEXT_MISSED_FIELD: 'FILL_NEXT_MISSED_FIELD',
}

export default types
