import React, { Suspense, useMemo } from 'react'
import { Route, Routes, Navigate, useLocation } from 'react-router-dom'
import cls from 'classnames'
import { ToastContainer } from 'react-toastify'
import { useTheme } from 'context/theme'

import ScrollToTop from 'utils/scrollToTop'
import Header from 'components/Header'
import Home from 'containers/Home'
import routes from 'routing/path'
import Footer from 'components/Footer'
import PersistReference from 'components/PersistReference'

import { useUserSettings } from 'context/userSettings'
import styles from './app.module.scss'
import ProtectedRoute from './components/ProtectedRoute'
import { isDevOrDevEnvironment } from './constants/environments'

const About = React.lazy(() => import('containers/About'))
const Answer = React.lazy(() => import('containers/Help/Answer'))
const Jobs = React.lazy(() => import('containers/Jobs'))
const NotFound = React.lazy(() => import('containers/NotFound'))
const Placeholder = React.lazy(() => import('containers/Placeholder'))
const PromisePage = React.lazy(() => import('containers/Promise'))

const Signup = React.lazy(() => import('containers/Signup'))
const Signin = React.lazy(() => import('containers/Signin'))
const UnexpectedErr = React.lazy(() => import('containers/UnexpectedErr'))
const Emoji = React.lazy(() => import('containers/Emoji'))
const ResetPassword = React.lazy(() => import('containers/ResetPassword'))

const User = React.lazy(() => import('containers/User'))

function App() {
  const location = useLocation()
  const { theme } = useUserSettings()
  const { isLight } = useTheme()

  const isUserPage = useMemo(
    () => location.pathname.startsWith('/verifiedbadge'),
    [location.pathname],
  )

  const isEmojiModelsPage = useMemo(
    () => location.pathname.startsWith(routes.withoutAuth.emoji),
    [location.pathname],
  )

  const renderFooter = () => {
    if (isUserPage) return null
    if (isEmojiModelsPage) return null

    return <Footer />
  }

  return (
    <div
      className={cls(styles.main_container, styles[theme], {
        [styles.dark]: !isLight,
        [styles.user_page]: isUserPage,
      })}
    >
      <main id="main" className={styles.main}>
        {!isUserPage && <Header />}

        <PersistReference>
          <ScrollToTop>
            <div className={cls(styles.page_container, { [styles.flex]: isUserPage })}>
              <Suspense fallback="">
                <Routes>
                  <Route path={routes.withoutAuth.home} element={<Home />} />
                  <Route path={routes.withoutAuth.about} element={<About />} />

                  {isDevOrDevEnvironment && (
                    <Route path={routes.withoutAuth.jobs} element={<Jobs />} />
                  )}

                  <Route path={routes.withoutAuth.help} element={<Placeholder />} />
                  <Route path={routes.withoutAuth.answer} element={<Answer />} />
                  <Route path={routes.withoutAuth.promise} element={<PromisePage />} />
                  <Route path={routes.withoutAuth.signup} element={<Signup />} />
                  <Route path={routes.withoutAuth.signin} element={<Signin />} />
                  <Route path={routes.withoutAuth.placeholder} element={<Placeholder />} />
                  <Route path={routes.withoutAuth.emoji} element={<Emoji />} />
                  <Route path={routes.withoutAuth.error} element={<UnexpectedErr />} />
                  <Route path={routes.withoutAuth.notFound} element={<NotFound />} />
                  <Route
                    path={routes.withoutAuth.user.index}
                    element={<ProtectedRoute component={User} />}
                  />

                  <Route path={routes.withoutAuth.user.index} element={<User />} />
                  <Route path={routes.withoutAuth.user.publicProfile} element={<User />} />
                  <Route path={routes.withoutAuth.reset_password} element={<ResetPassword />} />

                  <Route path="*" element={<Navigate to="/" replace />} />
                </Routes>
              </Suspense>
            </div>
          </ScrollToTop>
        </PersistReference>

        {renderFooter()}
      </main>
      <ToastContainer />
    </div>
  )
}

export default App
