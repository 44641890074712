const stage = process.env.REACT_APP_STAGE

const marketUrl = {
  production: window.env.marketUrl.production,
  preProd: window.env.marketUrl.preprod,
  stage: window.env.marketUrl.stage,
  development: window.env.marketUrl.development,
}

export default marketUrl[stage] || marketUrl.production
