import { useState, memo } from 'react'
import { useLocation, NavLink } from 'react-router-dom'
import cls from 'classnames'
import T, { PropTypes } from 'prop-types'
import peerIcon from 'assets/images/logo/peer.svg'
import { socialLinks } from './utils'

import styles from './nav_items.module.scss'

function NavItems({ links, onClick }) {
  const [hovered, setHovered] = useState('')
  const [toggleIcon, setToggleIcon] = useState(false)
  const { pathname } = useLocation()

  const handleClickSocial = () => {
    setToggleIcon(!toggleIcon)
  }

  return (
    <div className={styles.container}>
      <div className={styles.link_wrapper}>
        {links.map((link) =>
          link.router ? (
            <NavLink
              key={link.title}
              to={link.to}
              onClick={onClick}
              className={cls(styles.link, {
                [styles.hover]: hovered === link.title,
              })}
              onMouseEnter={() => setHovered(link.title)}
              onMouseLeave={() => setHovered('')}
            >
              {link.title}
            </NavLink>
          ) : (
            <a
              key={link.title}
              href={link.to}
              target="_blank"
              rel="noreferrer"
              onClick={onClick}
              className={cls(styles.link, hovered === link.title && styles.hover)}
              onMouseEnter={() => setHovered(link.title)}
              onMouseLeave={() => setHovered('')}
            >
              {link.title}
            </a>
          ),
        )}
      </div>
      <div
        className={cls(styles.social_section, {
          [styles.social_section__clicked]: toggleIcon,
        })}
      >
        {socialLinks.map(({ icon, to, type, XIcon }) =>
          icon ? (
            <a key={type} href={to}>
              {icon}
            </a>
          ) : (
            <XIcon toggleIcon={toggleIcon} handleClickSocial={handleClickSocial} />
          ),
        )}
      </div>
      <div className={styles.copyright}>
        <span>© PEER 2023. MADE WITH LOVE.</span>
        <img src={peerIcon} alt="" />
      </div>
    </div>
  )
}

const links = PropTypes.shape({
  title: T.string,
  to: T.string,

  router: T.bool,
  map: T.func,
})

NavItems.propTypes = {
  links: T.arrayOf(links),
  onClick: T.func,
}

export default memo(NavItems)
