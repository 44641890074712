import { takeLatest, all, put, call } from 'redux-saga/effects'
import path from 'components/API/Paths'
// eslint-disable-next-line import/no-cycle
import api from 'utils/apiClient'
import types from '../types'

function* getUserAchievements({ payload }) {
  try {
    const { data, status } = yield call(() =>
      api.get(`${path.getPublicUserAchievements}${payload.name}`),
    )

    if (status < 200 || status >= 300) throw new Error('Something went wrong')
    yield put({ type: types.GET_ACHIEVEMENTS_SUCCESS, payload: { ...data } })
  } catch (err) {
    yield put({ type: types.GET_ACHIEVEMENTS_FAILURE })
  }
}

export function* rootSagaAchievements() {
  yield all([takeLatest(types.GET_ACHIEVEMENTS_START, getUserAchievements)])
}
