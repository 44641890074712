import { useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import path from 'components/API/Paths'
import api from 'utils/apiClient'

import { setProfileData } from 'core/profile/actionCreators'
import { setRefProfile } from 'core/refProfile/actionCreators'
import useIsProfilePublic from 'hooks/useIsProfilePublic'

export default function useFetchUser() {
  const [data, setData] = useState()
  const [status, updateStatus] = useState('idle') // idle, loading, error
  const [userData, setUserData] = useState()
  const [errorMsg, setErrorMsg] = useState('')
  const [imgRes, setImgRes] = useState()
  const [image, setImage] = useState()
  const [shouldImageDelete, setShouldImageDelete] = useState(false)
  const navigate = useNavigate()
  const location = useLocation()
  const dispatch = useDispatch()
  const isProfilePublic = useIsProfilePublic()
  const profileData = useSelector((state) => state.profile?.data)

  const getData = async () => {
    updateStatus('loading')
    setErrorMsg('')

    try {
      if ((!profileData || !Object.keys(profileData).length !== 0) && !isProfilePublic) {
        const response = await api.get(`${path.fetchUser}?id=${new Date().getTime()}`)
        setData(response.data)
        dispatch(setProfileData(response.data))
        dispatch(setRefProfile(response.data))
      } else {
        setData(profileData)
      }

      setUserData('')
      updateStatus('success')

      updateStatus('idle')
    } catch (error) {
      updateStatus('error')
      setUserData('')
    }
    updateStatus('idle')
  }

  const uploadImage = async () => {
    updateStatus('loading')
    setErrorMsg('')

    try {
      const response = await api.post(path.uploadImage, {
        data: image,
      })
      setImgRes(response)

      updateStatus('success')
    } catch (error) {
      updateStatus('error')
      setImgRes(error)
    }
    updateStatus('idle')
  }

  const deleteUserAvatar = async () => {
    updateStatus('loading')
    setErrorMsg('')

    try {
      const response = await api.delete(path.deleteImage)
      setImgRes(response)

      updateStatus('success')
    } catch (error) {
      updateStatus('error')
      setImgRes(error)
    }
    updateStatus('idle')
  }

  useEffect(() => {
    if (userData) getData(userData)
  }, [userData])

  useEffect(() => {
    if (image) uploadImage(image)
  }, [image])

  useEffect(() => {
    if (shouldImageDelete) deleteUserAvatar()
  }, [shouldImageDelete])

  return { data, status, imgRes, setImage, errorMsg, setUserData, setShouldImageDelete }
}
