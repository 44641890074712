import cls from 'classnames'
import T from 'prop-types'
import styles from './componentContainer.module.scss'

function ComponentContainer({ children, noVertPadding }) {
  return (
    <div className={cls(styles.container, { [styles.noVertPadding]: noVertPadding })}>
      {children}
    </div>
  )
}

ComponentContainer.propTypes = {
  noVertPadding: T.bool,
  children: T.node,
}

export default ComponentContainer
