import { memo, useRef, useEffect } from 'react'
import cls from 'classnames'
import T from 'prop-types'
import BurgerMenu from './components/BurgerMenu/index'
import NavItems from './components/NavItems'

import styles from './styles.module.scss'

function MobileMenu({ isOpen, setIsOpen }) {
  const dropdownRef = useRef(null)

  const links = [
    {
      title: 'about',
      to: '/about',
      router: true,
    },
    {
      title: 'help',
      to: '/help',
      router: true,
    },
    {
      title: 'jobs',
      to: '/placeholder',
      router: true,
    },
    {
      title: 'assets',
      to: '/placeholder',
      router: true,
    },
    {
      title: 'promise',
      to: '/promise',
      router: true,
    },
  ]

  useEffect(() => {
    function handleClickOutside(event) {
      if (event.target.id !== 'togglenav') {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
          setIsOpen(false)
        }
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [dropdownRef])

  function onClick(e) {
    e.stopPropagation()
    if (e.currentTarget.id === 'togglenav') {
      setIsOpen((prev) => !prev)
    }
  }

  return (
    <div className={styles.wrapper}>
      <BurgerMenu isOpen={isOpen} onClick={(e) => onClick(e)} />
      <div className={cls({ [styles.dropdown]: true, [styles.open]: isOpen })} ref={dropdownRef}>
        <NavItems links={links} onClick={() => setIsOpen(false)} />
      </div>
    </div>
  )
}

MobileMenu.propTypes = {
  isOpen: T.bool,
  setIsOpen: T.func,
}

export default memo(MobileMenu)
