import { takeLatest, all, put } from 'redux-saga/effects'
import types from '../types'

function* setReferenceSite({ payload }) {
  yield put({ type: types.SET_REFERENCE_SITE, payload })
}

export function* rootSagaReferenceSite() {
  yield all([takeLatest(types.ADD_REFERENCE_SITE, setReferenceSite)])
}
