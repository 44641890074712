import { useEffect, useState, createContext } from 'react'
import { ThemeProvider } from 'context/theme'

import App from './App'

import 'react-toastify/dist/ReactToastify.css'
import './index.scss'

export default function Application() {
  return (
    <ThemeProvider>
      <App />
    </ThemeProvider>
  )
}
